import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { notification } from 'antd';
import { apiClient } from '@amplement/backend-connector';

const { eventEmitter } = apiClient;

type ErrorManagerContextType = {
    error: unknown;
    setError: React.Dispatch<React.SetStateAction<unknown>>;
};

const defaultState: ErrorManagerContextType = {
    error: null,
    setError: () => {},
};

const ErrorManagerContext = createContext(defaultState);

const ErrorManagerProvider = ({ children }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const [error, setError] = useState<unknown>(null);
    const enable = localStorage.getItem('_ENABLE_NEW_ERROR_MANAGER') === "1";

    const contextValue = useMemo(() => ({ error, setError }), [error]);

    useEffect(() => {
        const handleError = (err) => {
            if (enable) {
                setError(err);
                notificationApi.error({
                    message: `${err?.response?.status} ${err?.message}`,
                    description: err?.response?.data?.message,
                    placement: 'topRight',
                    duration: 2,
                });
            }
        };

        eventEmitter.on('apiError', handleError);

        return () => {
            eventEmitter.off('apiError', handleError);
        };
    }, []);

    return (
        <>
            {contextHolder}
            <ErrorManagerContext.Provider value={contextValue}>
                {children}
            </ErrorManagerContext.Provider>
        </>
    );
};

type ErrorManagerContextConsumerProps = {
    children: React.ReactElement;
};

export const ErrorManagerContextConsumer = React.memo(({ children }: ErrorManagerContextConsumerProps) => {
    const { error, setError } = useContext(ErrorManagerContext);

    return React.cloneElement(children, { error, setError });
});

export default ErrorManagerProvider;
