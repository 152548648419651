import React from 'react';
import ToastStack from 'components/Archives/ToastStack';
import ToastInformation from 'components/Archives/ToastInformation';
import * as notificationTypes from 'types/notification';
import { types as bcTypes } from '@amplement/backend-connector';

const isNewNetworkErrorManagerEnabled = localStorage.getItem('_ENABLE_NEW_ERROR_MANAGER') === "1";

function getToast(notification, fromBC) {
    switch (notification.type) {
        default:
            if (fromBC) { // from BC
                return {
                    ToastComponent: () => (
                        <ToastInformation
                            fromBC={fromBC}
                            message={notification.message}
                            title={notification.title}
                            referenceError={notification.referenceError}
                            error={notification.error}
                            values={notification.model}
                            severity={notification.error || notification.referenceError ? 'error' : 'info' }
                            duration={5}
                        />
                    ),
                    key: notification.key,
                    duration: notification.duration,
                };
            }
            return { // from WC
                ToastComponent: () => (
                    <ToastInformation
                        fromBC={fromBC}
                        title={notification.title}
                        values={notification.values}
                        severity={notification.severity}
                        referenceError={notification.referenceError}
                        duration={5}
                    />
                ),
                key: notification.key,
                duration: notification.duration
            };

    }

}

function isDisplayableNotification(notification) {
    const isNetworkErrorNotification = notification?.error?.code === "ERR_BAD_REQUEST";
    return !isNetworkErrorNotification || !isNewNetworkErrorManagerEnabled;
}

export default () => next => (action) => {
    switch (action.type) {
        case 'BATCHING_REDUCER.BATCH':
            action.payload.forEach((batchedActions) => {
                const shouldBeDisplayed = isDisplayableNotification(batchedActions?.notification);
                if (batchedActions.type === notificationTypes.NOTIFICATION_ADD && (
                    batchedActions.notification?.error?.message === undefined ||
                    batchedActions.notification?.error?.message?.indexOf('Request failed with status code 403') === -1) &&
                    shouldBeDisplayed) {
                    ToastStack.open(getToast(batchedActions.notification));
                }
                if (batchedActions.type === bcTypes.notification.NOTIFICATION_ADD && (
                    batchedActions.notification?.error?.message === undefined ||
                    batchedActions.notification?.error?.message?.indexOf('Request failed with status code 403') === -1) &&
                    shouldBeDisplayed) {
                    ToastStack.open(getToast(batchedActions.notification, true));
                }
                return null;
            });
            next(action);
            return;

        case notificationTypes.NOTIFICATION_ADD: {
            const shouldBeDisplayed = isDisplayableNotification(action?.notification);
            if ((action.notification?.error?.message === undefined ||
                action.notification?.error?.message?.indexOf('Request failed with status code 403') === -1) &&
                shouldBeDisplayed) {
                ToastStack.open(getToast(action.notification));
            }
            return;
        }

        case bcTypes.notification.NOTIFICATION_ADD: {
            const shouldBeDisplayed = isDisplayableNotification(action?.notification);
            if ((action.notification?.error?.message === undefined ||
                action.notification?.error?.message?.indexOf('Request failed with status code 403') === -1) &&
                shouldBeDisplayed) {
                ToastStack.open(getToast(action.notification, true));
            }
            return;
        }

        default:
            next(action);

    }

};
